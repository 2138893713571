import React from "react";
import Project from "./Project";

function Projects() {
  return (
    <div id="projects">
      <Project />
    </div>
  );
}

export default Projects;
